
import { Vue, Component } from "vue-property-decorator";
import { ILoginDetails } from "@/models/LoginDetails";
import InputField from "@/components/InputField.vue";
import PageLoader, { PageLoaderState } from "@/components/PageLoader.vue";
import admin from "@/store/modules/AdminModule";

@Component({
  components: {
    InputField,
    PageLoader
  }
})
export default class AdminLogin extends Vue {
  loginDetails: ILoginDetails = { email: "", password: "", rememberMe: false };
  pageLoaderState = PageLoaderState.Completed;
  errorMessage: string = "";

  async login() {
    this.pageLoaderState = PageLoaderState.Loading;
    await admin
      .login(this.loginDetails)
      .then(async () => {
        this.pageLoaderState = PageLoaderState.Completed;
        await this.$router.push("/admin/dashboard");
      })
      .catch(() => {
        this.pageLoaderState = PageLoaderState.Completed;
        this.errorMessage = "LOGIN_ERROR_MESSAGE";
      });
  }
}
